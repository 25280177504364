.public-DraftStyleDefault-block {
    margin: 0;
}
.public-DraftStyleDefault-ol, .public-DraftStyleDefault-ul {
    margin: 0;
}
blockquote {
    display: block;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    unicode-bidi: isolate;
    border-color: #ddd !important;
}
pre {
    display: block;
    font-family: inherit !important;
    unicode-bidi: isolate;
    white-space: pre;
    margin: 0;
}